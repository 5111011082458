<template>
  <div v-if="isOpen" class="modal-overlay payment-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button @click="closeModal" class="close-btn">
          <img :src="closeImg">
        </button>
      </div>

      <!-- Stepper with Line and Circles -->
      <div class="stepper">
        <div :class="['step', { 'step-complete': step >= 1 }]">
          <div class="circle"></div>
        </div>
        <div class="line"></div>
        <div :class="['step', { 'step-complete': step >= 2 }]">
          <div class="circle"></div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <span>طريقة الدفع</span>
        <span>إتمام الدفع</span>
      </div>
      <!-- Step 1: Select Payment Method -->
      <div v-if="step === 1">
        <h3 class="text-end">اختر طريقة الدفع</h3>
        <div class="radio-option">
          <input type="radio" id="electronic" value="electronic" v-model="selectedOption">
          <label for="electronic" class="electronic-label">
            المحفظة الالكترونية
            <span class="radio-circle"></span>
          </label>
        </div>
        <div class="radio-option">
          <input type="radio" id="code" value="code" v-model="selectedOption">
          <label for="code" class="code-label">
            إدخال كود
            <span class="radio-circle"></span>
          </label>
        </div>
        <button @click="nextStep" class="next-btn" :disabled="!selectedOption">التالي</button>
      </div>

      <!-- Step 2: Display Content Based on Selected Option -->
      <div v-if="step === 2">
        <h3 v-if="selectedOption === 'electronic'">رقم المحفظة الالكترونية</h3>
        <h3 v-if="selectedOption === 'code'">أدخل كود الدفع</h3>
        <div v-if="selectedOption === 'electronic'" class="elec-pay">
          <!-- <button
            :class="{ selected: selectedPayment === 'master' }"
            @click="selectPayment('master')"
          >
            <img :src="master" alt="master card">
          </button>
          <button
            :class="{ selected: selectedPayment === 'visa' }"
            @click="selectPayment('visa')"
          >
            <img :src="visa" alt="visa">
          </button>
          <button
            :class="{ selected: selectedPayment === 'paypal' }"
            @click="selectPayment('paypal')"
          >
            <img :src="payp" alt="paypal">
          </button> -->
          <div class="phone-copy">
           <div class="d-flex justify-content-end align-items-center gap-2">
            <p @click="copyToClipboard(number)">
             {{ number }}
            </p>
            <div class="copy-icon">
              <img :src="copy" alt="copy">
            </div>
           </div>
            <p v-if="copied" class="d-block">Copied to clipboard!</p>
          </div>
       <div class="d-flex gap-2 justify-content-center py-5 pay-btns-container flex-wrap">
        <button
            @click="openWhatsApp"
            class="screen-btn col-md-5 col-sm-12"
          >
          إرسال صورة للتحويل
          </button>
          <button
          @click="subscribeUser"
            class="complete-btn col-md-5 col-sm-12"
          >
          تأكيد الحجز
          </button>
       </div>
       <p v-if="subscriptionSuccess" class="success-message">تم الاشتراك بنجاح  </p>
        </div>
        <div v-if="selectedOption === 'code'">
          <input type="text" v-model="paymentCode" placeholder="أدخل الكود" class="code-input"/>
          <button @click="completePayment" class="complete-pay-btn">إتمام الدفع</button>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import apiService from '@/apiService'; // Import apiService instead of apiClient

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: Number, // Accept courseId as a prop
      required: true,
    },
  },
  data() {
    return {
      closeImg: require('@/assets/images/close-circle.svg'),
      copy: require('@/assets/images/document-copy.svg'),
      step: 1,
      selectedOption: '',
      paymentCode: '',
      selectedPayment: null,
      number: '01013906677', 
      copied: false, 
      phoneNumber: "201118500056", 
      subscriptionSuccess: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetStepper();
    },
    nextStep() {
      if (this.selectedOption) {
        this.step++;
      }
    },
    selectPayment(method) {
      this.selectedPayment = method;
      console.log('Selected Payment:', this.selectedPayment);
    },
    completePayment() {
      this.closeModal();
    },
    resetStepper() {
      this.step = 1;
      this.selectedOption = '';
      this.paymentCode = '';
    },
    copyToClipboard(number) {
      const input = document.createElement('input');
      input.setAttribute('value', number);
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999); 
      document.execCommand('copy');
      document.body.removeChild(input);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    openWhatsApp() {
      const url = `https://wa.me/${this.phoneNumber}`;
      window.open(url, '_blank'); 
    },
    subscribeUser() {
       // Include the courseId in the subscription data
       const subscriptionData = {
        course_id: this.courseId,
        // other subscription-related data
      };
      // Call the subscription API
      apiService.subscribe(subscriptionData) // Correctly refer to apiService.subscribe
        .then((response) => {
          console.log('Subscription successful:', response.data);
          this.subscriptionSuccess = true;

          // Handle successful subscription here (e.g., show a confirmation message)
        })
        .catch((error) => {
          console.error('Subscription failed:', error);
          // Handle error (e.g., show an error message)
        });
    }
  },
};
</script>


  
  <style scoped>
  .success-message {
  color: green; /* Optional: make the success message green */
  font-weight: 700;
}
  .payment-modal, .payment-modal h3{
    font-size: 16px;
  }
  .payment-modal h3{
    margin:  20px 0;
    color: #000;  
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    width: 500px;
    max-width: 90%;
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-start;
  }
  
  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0;
  }
  .close-btn img{
    width: 35px;
    height: 35px;
  }
  
  .stepper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .circle {
    width: 24px;
    height: 24px;
    border: 2px solid gray;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .step-complete .circle {
    border-color: #FAAF43;
    background-color: #FAAF43;
    color: white;
  }
  
  .step-complete .circle::before {
    content: '✔';
    position: absolute;
    font-size: 14px;
    color: white;
  }
  
  .line {
    height: 2px;
    width: 345px;
    background-color: #FAAF43;
    align-self: flex-start;
    margin-top: 12px;
  }
  
  .stepper span {
    margin-top: 8px;
    color: gray;
  }
  
  .step-complete span {
    color: #FAAF43;
  }
  
  .radio-option {
    margin-bottom: 20px;
    text-align: right;
    border-radius: 8px;
    border: 1.67px solid #FAAF43;
    padding: 10px;
    display: flex;
  }
  .radio-option input{
    margin-left: 10px;
  }
  input[type="radio"] {
    appearance: none;
    width: 21px;
    height: 21px;
    border: 1px solid #7b7b7b;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
    /* margin-top: 25%; */
}
input[type="radio"]:checked {
    background: #FAAF43;
    border: #FAAF43;
}
input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  .elec-pay button{
    border-radius: 6px;
    border: 1px solid #BBB;
    background: #FFF;
    height: 60px;
    padding: 7px;
    margin: 20px;
    min-width: 100px;
  }
  .elec-pay button img{
    width: 60px;
  }
  .selected{
  border: 2px solid #FAAF43 !important;
  }
  .next-btn,.complete-pay-btn {
    margin-top: 20px !important;
    background-color: #FAAF43 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    cursor: pointer;
    border-radius: 8px !important;
    width: 70% !important;
    height: fit-content !important;
  }
  .complete-btn{
    /* margin-top: 20px !important; */
    background-color: #FAAF43 !important;
    color: white !important;
    border: none !important;
    padding: 10px 20px !important;
    cursor: pointer;
    border-radius: 8px !important;
    height: fit-content !important;
    margin: 0 !important;
    font-weight: 600;
  }
  .code-input{
    width: 70%;
    border-radius: 8px;
    background: #FAFAFA;
    border: none;
    padding: 10px;
  }
  .code-input::placeholder{
    color: #808080;
    font-family: 18px;
  }

  .copy-icon{
    background: #FAAF43;
    border-radius: 12px;
    width: fit-content;
    padding: 7px 15px;
  }
.phone-copy{
  border: 1px solid #FAAF43;
    padding: 5px;
    width: 50%;
    margin: auto;
    border-radius: 12px;
}
  .phone-copy p {
  cursor: pointer;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.screen-btn{
  border: 1px solid #FAAF43 !important;
  color: #FAAF43;
  height: fit-content !important;
  margin: 0 !important;
  padding: 10px 20px !important;
  font-weight: 600;
}
  </style>
  
  
  