<template>
  <div class="exam">
    <!-- Show this message if the user is not logged in -->
    <div v-if="!isLoggedIn" class="alert alert-warning alert-login">
      <p>يجب عليك تسجيل الدخول أولاً لعرض الأمتحان</p>
      <router-link to="/login" class="btn btn-primary">تسجيل الدخول</router-link>
    </div>

    <!-- Exam content (only visible if the user is logged in) -->
    <div v-else class="w-75 m-3 questions-container">
      <div class="watermark">
        <img :src="watermark" alt="watermark">
      </div>
      <!-- Question numbers -->
      <div class="question-numbers" v-if="questions.length > 0">
        <button
          v-for="(question, index) in questions"
          :key="index"
          @click="selectQuestion(index + 1)"
          :class="{ 'selected-question': selectedQuestion === index + 1 }"
        >
          {{ index + 1 }}
        </button>
      </div>

      <!-- Selected question and answers -->
      <div class="question-content" v-if="selectedQuestion !== null && questions.length > 0 && questions[selectedQuestion - 1]">
        <div class="questions">
          <div class="question-img-container">
            <img v-if="questions[selectedQuestion - 1].image" :src="questions[selectedQuestion - 1].image.original_url" alt="Question image" class="question-image" />
          </div>
          <p>{{ questions[selectedQuestion - 1].question }}</p>
        </div>

        <div class="answers">
          <label v-for="(answer, index) in questions[selectedQuestion - 1].answers" :key="index" class="gap-3">
            <div>
              <input
              type="radio"
              :name="'question' + selectedQuestion"
              :value="answer.id"
              v-model="userAnswers[selectedQuestion]"
            />
            {{ answer.answer }}
            </div>
            <div class="ans-img-conatiner">
              <img v-if="answer.image" :src="answer.image.original_url" alt="Answer image" class="answer-image"/>
              <span @click="openFullscreen(answer.image.original_url)" class="full-btn">
                <img :src="fullsreen" alt="">
              </span>
            </div>
          </label>
        </div>
      </div>

      <!-- Submit button -->
      <div v-if="questions.length > 0" class="submit-btn-container">
        <button type="submit" class="orange-btn" @click="submitExam">إنهاء الأمتحان</button>
      </div>

      <!-- Exam timer -->
      <div class="exam-time d-flex">
        <img :src="timerImg" alt="">
        <p>باقي من الوقت {{ formatTime(remainingTime) }} </p>
      </div>
    </div>

    <!-- Modal for displaying messages -->
    <custom-modal
      :visible="isModalOpen"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeModal"
      @confirm="closeModal"
    />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import apiService from '@/apiService'; // Adjust the path according to your project structure
import CustomModal from './CustomModal.vue';

export default {
  name: 'OpenExam',
  data() {
    return {
      questions: [],
      selectedQuestion: 1, // Start with the first question
      userAnswers: {}, // Store user's answers
      duration: 0, // Duration of the exam
      remainingTime: 0, // Time left in the exam
      timer: null, // Reference to the timer
      exam: null, // Store exam data
      examsign: require('@/assets/images/exam-sign.svg'),
      watermark: require('@/assets/images/watermark.png'),
      timerImg: require('@/assets/images/time.png'),
      fullsreen: require('@/assets/images/full.svg'),
      isModalOpen: false, // Control modal visibility
      modalTitle: '', // Modal title
      modalMessage: '', // Modal message
    };
  },
  components: {
    CustomModal,
  },
  computed: {
    ...mapGetters(['isLoggedIn']), // Retrieve if the user is logged in from the Vuex store
  },
  mounted() {
    const examId = this.$route.params.id; // Retrieve the exam ID from the route
    if (this.isLoggedIn) {
      this.fetchExamAndQuestions(examId); // Fetch the exam data if the user is logged in
    }
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    async fetchExamAndQuestions(examId) {
      try {
        const response = await apiService.getExamQuestions(examId);
        const examData = response.data.data; // Adjust based on your API response structure
        this.exam = examData; // Store exam details
        this.questions = examData.questions || []; // Store the questions
        this.duration = examData.duration || 0; // Store the duration
        this.remainingTime = this.duration * 60; // Convert the duration to seconds
        this.startTimer(); // Start the countdown timer
      } catch (error) {
        console.error('Error fetching exam and questions:', error);
      }
    },
    selectQuestion(number) {
      this.selectedQuestion = number; // Change the selected question
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          this.stopTimer();
          this.submitExam(); // Automatically submit when time runs out
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer); // Stop the timer
      this.timer = null;
    },
    async submitExam() {
      try {
        const payload = {
          answers: {},
        };

        // Collect answers for each question
        Object.keys(this.userAnswers).forEach((questionIndex) => {
          const questionId = this.questions[questionIndex - 1].id;
          const answer = this.userAnswers[questionIndex];
          payload.answers[questionId] = answer;
        });

        const examId = this.$route.params.id; // Get the exam ID
        await apiService.submitExamAnswers(examId, payload); // Submit answers to the backend

        this.modalTitle = 'نجاح';
        this.modalMessage = 'تم إنهاء الأمتحان بنجاح';
        this.openModal();

        setTimeout(() => {
          this.$router.push({ name: 'Exams' });
        }, 3000);
      } catch (error) {
        console.error('Error submitting exam:', error);
        this.modalTitle = 'اعادة محاولة';
        this.modalMessage = 'لقد انهيت هذا الامتحان من قبل';
        this.openModal();

        setTimeout(() => {
          this.$router.push({ name: 'Exams' });
        }, 3000);
      }
    },
    openModal() {
      this.isModalOpen = true; // Show the modal
    },
    closeModal() {
      this.isModalOpen = false; // Hide the modal
    },
    openFullscreen(imageUrl) {
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    imgElement.style.width = '50%'; // Adjust this based on your design
    imgElement.style.height = 'auto';

    const fullscreenContainer = document.createElement('div');
    fullscreenContainer.style.position = 'fixed';
    fullscreenContainer.style.top = '0';
    fullscreenContainer.style.left = '0';
    fullscreenContainer.style.width = '100vw';
    fullscreenContainer.style.height = '100vh';
    fullscreenContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    fullscreenContainer.style.display = 'flex';
    fullscreenContainer.style.alignItems = 'center';
    fullscreenContainer.style.justifyContent = 'center';
    fullscreenContainer.style.zIndex = '1000';
    
    // Append the image to the container
    fullscreenContainer.appendChild(imgElement);
    
    // Close the fullscreen when clicking on the image or outside it
    fullscreenContainer.onclick = () => {
      document.body.removeChild(fullscreenContainer);
    };

    document.body.appendChild(fullscreenContainer);
  },
  },
  beforeUnmount() {
    this.stopTimer(); // Stop the timer when the component is destroyed
  },
};
</script>

<style>
.submit-btn-container, .exam-time {
  margin: 10px auto;
  width: max-content;
}
.exam {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-numbers {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.question-numbers button {
  margin: 0 5px;
  padding: 10px 17px;
  border-radius: 7px;
  background: #FAFAFA;
  color: #000;
  font-family: Cairo;
  font-size: 18px;
  font-weight: 700;
  border: none;
  margin-bottom: 12px;
}

.question-content {
  text-align: center;
}

.questions, .exam-time {
  border-radius: 7px;
  background: #FAFAFA;
  padding: 10px 20px;
}
.question-content p {
  color: #000;
  text-align: center;
  font-family: Cairo;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.answers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}

.exam-time p {
  color: #000;
  font-family: Cairo;
  font-size: 23px;
  font-weight: 600;
  margin: 0;
}
</style>
